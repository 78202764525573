<template>
    <!-- 彈窗容器 -->
    <vs-popup :active.sync="active" :title="title" style="z-index: 51001" @close="close">
        <div class="grid grid-cols-1 gap-8">
            <!-- 攝影機區塊 -->
            <div>
                <div class="flex flex-wrap justify-evenly items-center">
                    <!-- 即時攝影機畫面 -->
                    <video class="w-64 border border-solid border-grey" ref="video" autoplay></video>
                    <!-- 點擊拍照並掃描 -->
                    <vs-button class="m-8 rounded whitespace-no-wrap" @click="takePhoto"> 掃描 </vs-button>
                </div>
                <!-- 隱藏的畫布，用於擷取拍照影像 -->
                <canvas ref="canvas" style="display: none"></canvas>
            </div>

            <!-- 表單資料輸入區 -->
            <div class="grid grid-cols-1 gap-4">
                <!-- 單位選擇：只有會員新增時才顯示 -->
                <div v-if="shouldShowUnit">
                    <label class="vs-input--label">* 單位</label>
                    <unit-tree-select :units="treeUnits" v-model="eid" />
                </div>

                <!-- 姓名 -->
                <vs-input size="large" :label="'* ' + $t('membersMgmt.addmember.name')" class="w-auto text-primary" v-model="name"></vs-input>

                <!-- 會員帳號/身分證 -->
                <vs-input size="large" :label="'* ' + $t('membersMgmt.addmember.account')" class="w-auto text-primary" v-model="id"></vs-input>

                <!-- 健保卡號 -->
                <vs-input size="large" :label="$t('membersMgmt.addmember.cardno')" class="w-auto text-primary" v-model="hid"></vs-input>

                <!-- 生日 -->
                <vs-input size="large" :label="$t('membersMgmt.addmember.dob')" class="w-auto text-primary" v-model="dob"></vs-input>

                <!-- 身高：整篩活動不顯示 -->
                <vs-input v-if="shouldShowHeight" size="large" :label="$t('membersMgmt.addmember.height')" type="number" class="w-auto text-primary" v-model="height"></vs-input>

                <!-- 自動註冊 -->
                <vs-checkbox v-model="autoRegister">自動註冊會員帳號（預設密碼為帳號後六碼）</vs-checkbox>
            </div>

            <!-- 按鈕操作區 -->
            <div class="flex justify-end gap-4">
                <!-- 關閉 -->
                <vs-button color="dark" type="border" class="rounded whitespace-no-wrap" @click="close">
                    {{ $t('membersMgmt.addmember.close_btn') }}
                </vs-button>

                <!-- 義診報名 -->
                <vs-button v-if="type === 'free_clinic'" class="rounded whitespace-no-wrap" @click="joinFreeClinicEvent"> 報名 </vs-button>

                <!-- 整篩報名 -->
                <vs-button v-if="type === 'screening'" class="rounded whitespace-no-wrap" @click="joinScreeningEvent"> 報名 </vs-button>

                <!-- 新增會員 -->
                <vs-button v-if="type === 'member'" class="rounded whitespace-no-wrap" @click="createMember"> 新增 </vs-button>
            </div>
        </div>
    </vs-popup>
</template>

<script>
import { scanCard, joinHpaEvent, joinEvent, addMember } from '@/api/user'
import { mapState } from 'vuex'
import UnitTreeSelect from '@/components/Module/UnitTreeSelect.vue'

export default {
    components: { UnitTreeSelect },
    props: {
        // 控制彈窗顯示/隱藏
        memberPopup: {
            type: Boolean,
            default: false,
            required: true,
        },
        // 義診活動 ID
        event_id: {
            type: Number,
            default: 0,
            required: false,
        },
        // 整篩活動 ID
        hpa_event_id: {
            type: Number,
            default: 0,
            required: false,
        },
        // 可為 member / free_clinic / screening
        type: {
            type: String,
            default: 'member',
            required: false,
        },
        // 預設單位 ID
        default_eid: {
            type: Number,
            default: 0,
            required: false,
        },
    },
    data() {
        return {
            active: false, // 內部控制 popup
            stream: null, // 攝影機串流
            photo: null, // base64 後的拍照結果
            eid: null,
            name: '',
            id: '',
            hid: '',
            dob: '',
            height: '',
            autoRegister: false,
        }
    },
    computed: {
        ...mapState(['unit_list', 'partner_id']),
        // 動態產生單位樹
        treeUnits() {
            return this.$common.buildUnitTree()
        },
        // 彈窗標題
        title() {
            const map = {
                member: '新增會員',
                free_clinic: '義診活動報名',
                screening: '整篩活動報名',
            }
            return map[this.type] || ''
        },
        // 是否顯示單位選擇：僅會員新增才需選單位
        shouldShowUnit() {
            return this.type === 'member'
        },
        // 是否顯示身高：整篩不需填身高
        shouldShowHeight() {
            return this.type !== 'screening'
        },
    },
    watch: {
        // 監控父層控制的彈窗開關
        memberPopup: {
            handler(newVal) {
                this.active = newVal
                if (this.default_eid > 0) this.eid = this.default_eid
                if (newVal) {
                    this.startCamera()
                } else {
                    this.stopCamera()
                }
            },
            immediate: true,
        },
    },
    methods: {
        // 關閉彈窗時重置表單
        close() {
            this.eid = null
            this.name = ''
            this.id = ''
            this.hid = ''
            this.dob = ''
            this.height = ''
            this.$emit('closeMember')
        },
        // 啟動攝影機（先嘗試後置，不行再用前置）
        startCamera() {
            navigator.mediaDevices
                .getUserMedia({ video: { facingMode: 'environment' } })
                .then((stream) => {
                    this.stream = stream
                    this.$refs.video.srcObject = stream
                })
                .catch(() => {
                    navigator.mediaDevices
                        .getUserMedia({ video: true })
                        .then((stream) => {
                            this.stream = stream
                            this.$refs.video.srcObject = stream
                        })
                        .catch((err) => {
                            console.error('無法啟動攝像頭: ', err)
                        })
                })
        },
        // 停止攝影機
        stopCamera() {
            if (this.stream) {
                this.stream.getTracks().forEach((track) => track.stop())
                this.$refs.video.srcObject = null
                this.stream = null
            }
        },
        // 拍照並進行掃描
        takePhoto() {
            const video = this.$refs.video
            const canvas = this.$refs.canvas
            const context = canvas.getContext('2d')
            canvas.width = video.videoWidth
            canvas.height = video.videoHeight
            context.drawImage(video, 0, 0, canvas.width, canvas.height)
            let base64 = canvas.toDataURL('image/png')
            base64 = base64.replace(/^data:image\/[a-zA-Z]+;base64,/, '')
            this.photo = base64
            this.scanCard()
        },
        // 呼叫 API 解析卡片資訊
        async scanCard() {
            try {
                const res = await scanCard({ photo: this.photo })
                const data = res.data.data
                this.name = data.name || ''
                this.id = data.id || ''
                this.hid = data.hid || ''
                this.dob = data.dob || ''
            } catch (err) {
                console.error('掃描卡失敗: ', err)
            }
        },

        // 報名整篩活動：必填 account、name
        async joinScreeningEvent() {
            // 前端防呆檢查
            if (!this.id || !this.name) {
                let dialogPayload = []
                dialogPayload.content = '<div style="white-space: normal;">請填寫帳號與姓名</div>'
                dialogPayload.title = '整篩報名 - 錯誤'
                this.$bus.$emit('errDialog', dialogPayload)
                return
            }
            try {
                const payload = {
                    hpa_event_id: this.hpa_event_id,
                    account: this.id,
                    name: this.name,
                    dob: this.dob ? this.$common.formatDate(this.dob) : '',
                    insurance_id: this.hid,
                    is_checked: 1,
                    autoRegister: this.autoRegister,
                }
                const res = await joinHpaEvent(payload)
                if (res.data.status === 'OK') {
                    this.stopCamera()
                    this.$bus.$emit('joinEvent', res.data.data)
                    this.close()
                } else {
                    let dialogPayload = []
                    dialogPayload.content = '<div style="white-space: normal;">' + this.$t('membersMgmt.addmember.error.' + res.data.code) + '</div>'
                    dialogPayload.title = this.$t('membersMgmt.addmember.err_dialog_title')
                    this.$bus.$emit('errDialog', dialogPayload)
                }
            } catch (error) {
                console.error(error)
            }
        },

        // 報名義診活動：必填 account、name
        async joinFreeClinicEvent() {
            // 前端防呆檢查
            if (!this.id || !this.name) {
                let dialogPayload = []
                dialogPayload.content = '<div style="white-space: normal;">請填寫帳號與姓名</div>'
                dialogPayload.title = '義診報名 - 錯誤'
                this.$bus.$emit('errDialog', dialogPayload)
                return
            }
            try {
                const payload = {
                    event_id: this.event_id,
                    account: this.id,
                    name: this.name,
                    dob: this.dob ? this.$common.formatDate(this.dob) : '',
                    insurance_id: this.hid,
                    height: this.height,
                    autoRegister: this.autoRegister,
                }
                const res = await joinEvent(payload)
                if (res.data.status === 'OK') {
                    this.stopCamera()
                    this.$bus.$emit('joinEvent', res.data.data)
                    this.close()
                } else {
                    let dialogPayload = []
                    dialogPayload.content = '<div style="white-space: normal;">' + this.$t('membersMgmt.addmember.error.' + res.data.code) + '</div>'
                    dialogPayload.title = this.$t('membersMgmt.addmember.err_dialog_title')
                    this.$bus.$emit('errDialog', dialogPayload)
                }
            } catch (error) {
                console.error(error)
            }
        },

        // 新增會員：必填 eid、account、name
        async createMember() {
            // 前端防呆檢查
            if (!this.eid || !this.id || !this.name) {
                let dialogPayload = []
                dialogPayload.content = '<div style="white-space: normal;">請填寫單位、帳號與姓名</div>'
                dialogPayload.title = '新增會員 - 錯誤'
                this.$bus.$emit('errDialog', dialogPayload)
                return
            }
            try {
                this.$vs.loading()
                const payload = {
                    partner_id: this.partner_id,
                    account: this.id,
                    name: this.name,
                    dob: this.dob ? this.$common.formatDate(this.dob) : '',
                    insurance_id: this.hid,
                    eid: this.eid,
                    height: this.height,
                    autoRegister: this.autoRegister,
                }
                const res = await addMember(payload)
                this.$vs.loading.close()
                if (res.data.status === 'OK' || res.data.code === 'ERR_0011') {
                    this.stopCamera()
                    this.$bus.$emit('refreshMemberList')
                    this.close()
                } else {
                    let dialogPayload = []
                    dialogPayload.content = this.$t('membersMgmt.addmember.error.' + res.data.code)
                    dialogPayload.title = this.$t('membersMgmt.addmember.err_dialog_title')
                    this.$bus.$emit('errDialog', dialogPayload)
                }
            } catch (error) {
                this.$vs.loading.close()
                console.error(error)
            }
        },
    },
}
</script>
