<template>
    <div class="parentx">
        <vs-sidebar
            class="v-nav-menu items-no-padding"
            v-model="isVerticalNavMenuActive"
            ref="verticalNavMenu"
            default-index="-1"
            :click-not-close="clickNotClose"
            :reduce-not-rebound="reduceNotRebound"
            :parent="parent"
            :hiddenBackground="hiddenBackground"
            :reduce="reduce"
            v-hammer:swipe.left="onSwipeLeft"
        >
            <div @mouseenter="mouseEnter" @mouseleave="mouseLeave">
                <!-- Header -->
                <div class="header-sidebar flex items-end justify-between" slot="header">
                    <router-link class="vx-logo cursor-pointer flex items-center" to="/" v-slot="{ navigate, href }" custom>
                        <div @click="navigate" :href="href">
                            <img :src="uiConfig.logo.url.startsWith('http') ? uiConfig.logo.url : require(`@/assets/images/logo/${uiConfig.logo.url}`)" :style="uiConfig.logo.style" v-if="uiConfig.logo.url" />
                            <span v-if="uiConfig.logo.title">
                                <h4 class="vx-logo-text" :style="uiConfig.logo.title_style" v-show="isMouseEnter || !reduce">{{ geti18n(uiConfig.logo.title) }}</h4>
                            </span>
                        </div>
                    </router-link>
                    <div>
                        <!-- Close Button -->
                        <template v-if="showCloseButton">
                            <feather-icon icon="XIcon" class="m-0 cursor-pointer" @click="$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)" />
                        </template>
                        <!-- Toggle Buttons -->
                        <!-- <template v-else-if="!showCloseButton && !verticalNavMenuItemsMin">
              <feather-icon
                id="btnVNavMenuMinToggler"
                class="mr-0 cursor-pointer"
                :icon="reduce ? 'CircleIcon' : 'DiscIcon'"
                svg-classes="stroke-current"
                @click="toggleReduce(!reduce)" />
            </template> -->
                    </div>
                </div>
                <!-- /Header -->
                <!-- Header Shadow -->
                <div class="shadow-bottom" v-show="showShadowBottom" />
                <!-- Menu Items -->
                <VuePerfectScrollbar ref="verticalNavMenuPs" class="scroll-area-v-nav-menu pt-2 pb-16" :settings="settings" @ps-scroll-y="psSectionScroll">
                    <template v-for="(item, index) in uiConfig.menu">
                        <!-- Nav-Item -->
                        <v-nav-menu-item v-if="item.router" :key="`item-${index}`" :index="index" :to="item.router" :icon="item.icon" :isDisabled="item.disabled" v-show="item.show">
                            <!-- :slug="item.name" -->
                            <!-- :target="item.target" -->
                            <span class="truncate" v-show="!verticalNavMenuItemsMin"> {{ geti18n(item.i18n) }} </span>
                            <span class="cd-badge-text" v-if="undoneApptTotal != 0 && item.name == 'apptmgmt1'"> {{ undoneApptTotal }} </span>
                            <span class="cd-badge-text" v-if="crmOpenTotal != 0 && item.name == 'crm'"> {{ crmOpenTotal }} </span>
                            <span v-if="memberTotal != 0 && item.name == 'members'"> {{ '(' + memberTotal + ')' }} </span>
                            <span class="cd-badge-text" v-if="opinionActiveCount != 0 && item.name == 'opinion'"> {{ opinionActiveCount }} </span>
                            <span class="cd-badge-text" v-if="todoutotal != 0 && item.name == 'todolist'"> {{ todoutotal }} </span>
                            <span class="cd-badge-text" v-if="scheduletotal != 0 && item.name == 'schedule'"> {{ scheduletotal }} </span>
                            <span class="cd-badge-text" v-if="msgtotal != 0 && item.name == 'chat'"> {{ msgtotal }} </span>
                            <span class="cd-badge-text" v-if="notifytotal != 0 && item.name == 'notify'"> {{ notifytotal }} </span>
                            <!-- <vs-chip color="danger" class="ml-5" v-if="reservationTotal!=0 && item.name=='reservation'">
                  {{ reservationTotal }}
                </vs-chip> -->
                            <!-- <vs-chip color="danger" class="ml-5" v-if="opinionActiveCount!=0 && item.name=='opinion'">
                  {{ opinionActiveCount }}
                </vs-chip> -->
                            <!-- <vs-chip color="danger" class="ml-5" v-if="todoutotal!=0 && item.name=='todolist'">
                  {{ todoutotal }}
                </vs-chip> -->
                            <!-- <vs-chip color="danger" class="ml-5" v-if="scheduletotal!=0 && item.name=='schedule'">
                  {{ scheduletotal }}
                </vs-chip> -->
                            <!-- <vs-chip class="ml-auto" :color="item.tagColor" v-if="item.tag && (isMouseEnter || !reduce)">
                  {{ item.tag }}
                </vs-chip> -->
                            <!-- <vs-chip color="danger" class="ml-5" v-if="msgtotal!=0 && item.name=='chat'">
                  {{ msgtotal }}
                </vs-chip> -->
                            <!-- <vs-chip color="danger" class="ml-5" v-if="notifytotal!=0 && item.name=='notify'">
                  {{ notifytotal }}
                </vs-chip> -->
                        </v-nav-menu-item>
                        <!-- Nav-Group -->
                        <template v-else>
                            <v-nav-menu-group :key="`group-${index}`" :openHover="openGroupHover" :group="item" :groupIndex="index" :open="isGroupActive(item)" />
                        </template>
                        <!-- /Nav-Group -->
                    </template>
                </VuePerfectScrollbar>
                <!-- /Menu Items -->
            </div>
        </vs-sidebar>
        <!-- Swipe Gesture -->
        <div v-if="!isVerticalNavMenuActive" class="v-nav-menu-swipe-area" v-hammer:swipe.right="onSwipeAreaSwipeRight" />
        <!-- /Swipe Gesture -->
    </div>
</template>
<script>
import _ from 'lodash'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VNavMenuGroup from './VerticalNavMenuGroup.vue'
import VNavMenuItem from './VerticalNavMenuItem.vue'
import { getpermissionlist } from '@/api/user'
import { mapState } from 'vuex'

export default {
    name: 'v-nav-menu',
    components: {
        VNavMenuGroup,
        VNavMenuItem,
        VuePerfectScrollbar,
    },
    props: {
        openGroupHover: { type: Boolean, default: false },
        parent: { type: String },
        reduceNotRebound: { type: Boolean, default: true },
        // navMenuItems:     { type: Array,   required: true },
        title: { type: String },
        title2: { type: String },
        curtitle: { type: String },
        allianceTitle: { type: String },
        chengHsinTitle: { type: String },
        CLCTitle: { type: String },
        PingChrisTitle: { type: String },
        CamiansaintTitle: { type: String },
    },
    data: () => ({
        fontSize: 18,
        fontWeight: 800,
        clickNotClose: false, // disable close navMenu on outside click
        hiddenBackground: false,
        isMouseEnter: false,
        reduce: false, // determines if navMenu is reduce - component property
        showCloseButton: false, // show close button in smaller devices
        settings: {
            // perfectScrollbar settings
            maxScrollbarLength: 60,
            wheelSpeed: 1,
            swipeEasing: true,
        },
        showShadowBottom: false,
        emrFlag: false,
        menu: [],
    }),
    computed: {
        ...mapState(['todoutotal', 'crmOpenTotal', 'undoneApptTotal', 'windowWidth']),
        // todoutotal() {
        //     return this.$store.state.todoutotal
        // },
        scheduletotal() {
            return this.$store.getters.scheduletotal
        },
        opinionActiveCount() {
            return this.$store.getters.opinionActiveCount
        },
        partnerid() {
            return this.$store.getters.partner
        },
        memberTotal() {
            return this.$store.getters.memberTotal
        },
        // apptUndoneTotal() {
        //   return this.$store.state.apptUndoneTotal
        // },
        // crmOpenTotal() {
        //     return this.$store.state.crmOpenTotal
        // },
        msgtotal() {
            return this.$store.getters.msgtotal
        },
        notifytotal() {
            return this.$store.getters.notifytotal
        },
        isGroupActive() {
            return (item) => {
                const path = this.$route.fullPath
                // const routeParent = this.$route.meta ? this.$route.meta.parent : undefined
                let open = false
                let func = (item) => {
                    if (item.setting) {
                        item.setting.forEach((item) => {
                            if (item.router && path === item.router) {
                                open = true
                            } else if (item.setting) {
                                func(item)
                            }
                        })
                    }
                }
                func(item)
                return open
            }
        },
        isVerticalNavMenuActive: {
            get() {
                if (this.$store.state.windowWidth > 1200 && !this.emrFlag) {
                    return true
                }
                return this.$store.state.isVerticalNavMenuActive
            },
            set(val) {
                this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', val)
            },
        },
        layoutType() {
            return this.$store.state.mainLayoutType
        },
        reduceButton: {
            get() {
                return this.$store.state.reduceButton
            },
            set(val) {
                this.$store.commit('TOGGLE_REDUCE_BUTTON', val)
            },
        },
        isVerticalNavMenuReduced() {
            return Boolean(this.reduce && this.reduceButton)
        },
        verticalNavMenuItemsMin() {
            return this.$store.state.verticalNavMenuItemsMin
        },
        // windowWidth() {
        //     return this.$store.state.windowWidth
        // },
        uiConfig() {
            var _self = this
            var uiConfig = JSON.parse(localStorage.getItem('his_config'))
            if (_self.menu.length == 0) {
                return uiConfig
            }
            uiConfig.menu.forEach(function (v, i) {
                if (
                    i > 10 &&
                    !_.find(_self.menu, function (o) {
                        return o.name == v.name
                    })
                ) {
                    v.disabled = true
                }
            })
            return uiConfig
        },
    },
    watch: {
        $route() {
            let route = _.replace(_.lowerCase(_.split(this.$route.fullPath, '/', 2)[1]), ' ', '')
            this.emrFlag = route == 'emr2' || route == 'appthistory' || route == 'apptmgmt' || route == 'monitor' || route == 'membersmgmt' || route == 'freeclinic' || route == 'tb' || route == 'screening' || route == 'crm' || route == 'opinion'
            this.setVerticalNavMenuWidth()
            if (this.isVerticalNavMenuActive && this.showCloseButton) this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)
        },
        reduce(val) {
            const verticalNavMenuWidth = val ? 'reduced' : 'default'
            this.$store.dispatch('updateVerticalNavMenuWidth', verticalNavMenuWidth)

            setTimeout(function () {
                window.dispatchEvent(new Event('resize'))
            }, 100)
        },
        layoutType() {
            this.setVerticalNavMenuWidth()
        },
        reduceButton() {
            this.setVerticalNavMenuWidth()
        },
        windowWidth() {
            this.setVerticalNavMenuWidth()
        },
    },
    methods: {
        // 設定權限
        async set_permission() {
            var _self = this
            // 開啟有開放的權限
            await getpermissionlist().then(function (res) {
                if (res.data.status == 'OK') {
                    _self.menu = _.cloneDeep(res.data.item)
                }
            })
        },
        onSwipeLeft() {
            if (this.isVerticalNavMenuActive && this.showCloseButton) this.isVerticalNavMenuActive = false
        },
        onSwipeAreaSwipeRight() {
            if (!this.isVerticalNavMenuActive && this.showCloseButton) this.isVerticalNavMenuActive = true
        },
        psSectionScroll() {
            this.showShadowBottom = this.$refs.verticalNavMenuPs.$el.scrollTop > 0 ? true : false
        },
        mouseEnter() {
            if (this.reduce) this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', false)
            this.isMouseEnter = true
        },
        mouseLeave() {
            if (this.reduce) this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', true)
            this.isMouseEnter = false
        },
        setVerticalNavMenuWidth() {
            if (this.windowWidth > 1200 && !this.emrFlag) {
                if (this.layoutType === 'vertical') {
                    // Set reduce
                    this.reduce = this.reduceButton ? true : false

                    // Open NavMenu
                    this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)

                    // Set Menu Items Only Icon Mode
                    const verticalNavMenuItemsMin = this.reduceButton && !this.isMouseEnter ? true : false
                    this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', verticalNavMenuItemsMin)

                    // Menu Action buttons
                    this.clickNotClose = true
                    this.showCloseButton = false
                    this.hiddenBackground = true

                    const verticalNavMenuWidth = this.isVerticalNavMenuReduced ? 'reduced' : 'default'
                    this.$store.dispatch('updateVerticalNavMenuWidth', verticalNavMenuWidth)

                    return
                }
            }

            // Close NavMenu
            this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)

            // Reduce button
            if (this.reduceButton) this.reduce = false

            // Menu Action buttons
            this.showCloseButton = true
            this.clickNotClose = false
            this.hiddenBackground = false

            // Update NavMenu Width
            this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu')

            // Remove Only Icon in Menu
            this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', false)
        },
        toggleReduce(val) {
            this.reduceButton = val
            this.setVerticalNavMenuWidth()
        },
        // 判斷語系
        geti18n(items) {
            if (this.$i18n.locale == 'tw') {
                return items.tw
            } else if (this.$i18n.locale == 'cn') {
                return items.cn
            } else if (this.$i18n.locale == 'en') {
                return items.en
            }
        },
    },
    async mounted() {
        let route = _.replace(_.lowerCase(_.split(this.$route.fullPath, '/', 2)[1]), ' ', '')
        this.emrFlag = route == 'emr2' || route == 'appthistory' || route == 'apptmgmt' || route == 'monitor' || route == 'membersmgmt' || route == 'freeclinic' || route == 'tb' || route == 'screening' || route == 'crm' || route == 'opinion'
        await this.setVerticalNavMenuWidth()
        // 顯示所有權限
        await this.set_permission()
    },
}
</script>

<style lang="scss">
@import '@/assets/scss/vuexy/components/verticalNavMenu.scss';
</style>
