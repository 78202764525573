<template>
    <vs-popup :active.sync="active" :title="$t('crm.caseAssign')" @close="assignClose">
        <div class="min-h-lg" v-if="data">
            <div class="mb-1">
                <span>目前負責人：</span>
                <span v-if="data.doctor">{{ data.doctor.dname }}</span>
                <span v-else>未指派</span>
            </div>
            <div class="w-full flex">
                <vs-input :placeholder="'搜尋'" v-model="query" class="cd-form-group flex-grow" />
            </div>
            <div class="px-2 py-4 flex justify-between items-center rounded cursor-pointer hover:bg-grey-light" @click="assignCase(did, name)" v-if="did != data.did">
                <p>{{ name }}{{ $t('crm.myself') }}</p>
                <div class="flex items-center">
                    <feather-icon icon="PlusIcon" svgClasses="w-4 h-4"></feather-icon>
                </div>
            </div>
            <div class="px-2 py-4 flex justify-between items-center rounded cursor-pointer hover:bg-grey-light" v-if="item.did != data.did" v-for="(item, index) in getallMedicalNurse" :key="index" @click="assignCase(item.did, item.name)">
                <p>{{ item.name }}</p>
                <div class="flex items-center">
                    <feather-icon icon="PlusIcon" svgClasses="w-4 h-4"></feather-icon>
                </div>
            </div>
            <div class="px-2 py-4" v-if="getallMedicalNurse.length == 0">查無資料</div>
        </div>
    </vs-popup>
</template>
<script>
import { getSNs, updateSN, createSND, fetchAuthList } from '@/api/user'
import { debounce } from 'lodash'
import { mapState } from 'vuex'
export default {
    props: {
        assignPopup: {
            type: Boolean,
            default: false,
            required: true,
        },
        sn_id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            active: false,
            data: null,
            query: '',
            myMedicalAllNurse: [],
            tempMyMedicalAllNurse: [],
        }
    },
    watch: {
        assignPopup(n) {
            this.active = n
            if (n) {
                this.fetchAuthList()
                this.getSNs()
            }
        },
        query(n) {
            if (n != '') {
                var temp = _.filter(this.myMedicalAllNurse, function (o) {
                    return o.name.includes(n)
                })
                this.tempMyMedicalAllNurse = _.cloneDeep(temp)
            } else {
                this.tempMyMedicalAllNurse = _.cloneDeep(this.myMedicalAllNurse)
            }
        },
    },
    computed: {
        ...mapState(['partner_id', 'did', 'name']),
        getallMedicalNurse() {
            if (!this.tempMyMedicalAllNurse) return []
            // 最多顯示50筆資料
            return this.tempMyMedicalAllNurse.slice(0, 50)
        },
    },
    methods: {
        // 取服務記錄
        getSNs: debounce(async function () {
            try {
                const payload = {
                    sn_id: this.sn_id,
                    withDoctor: 1,
                }
                const res = await getSNs(payload)
                this.data = res.data.data.data[0]
            } catch (error) {
                console.error('取服務記錄失敗', error)
            }
        }, 300),
        // 關閉交辦案件視窗
        assignClose() {
            this.$emit('closeAssign')
        },
        // 交辦案件
        async assignCase(did, name) {
            try {
                const payload = {
                    sn_id: this.sn_id,
                    did: did,
                }
                await updateSN(payload)
                const note = this.$t('crm.assignMsg', { from_name: this.name, to_name: name })
                await this.createSND(note)
                payload.name = name
                this.$bus.$emit('assignSn', payload)
                this.assignClose()
            } catch (error) {
                console.error('交辦案件失敗', error)
            }
        },
        // 新增註記
        async createSND(note) {
            try {
                const payload = {
                    sn_id: this.sn_id,
                    note: note,
                }
                await createSND(payload)
            } catch (error) {
                console.error('新增註記失敗', error)
            }
        },
        // 取醫師可授權的醫事人員名單
        fetchAuthList() {
            fetchAuthList(this.partner_id).then((res) => {
                this.myMedicalAllNurse = _.cloneDeep(res.data.data)
                this.tempMyMedicalAllNurse = _.cloneDeep(res.data.data)
            })
        },
    },
}
</script>
