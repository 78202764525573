<template>
    <vs-popup fullscreen :active.sync="active" :title="'判讀'" style="z-index: 51001" @close="close">
        <div class="flex flex-col lg:flex-row gap-8" v-if="data">
            <div class="p-4 lg:w-2/3 w-full order-2 lg:order-1 mb-base flex flex-col flex-grow gap-8">
                <div class="w-full mb-base" v-if="data">
                    <div class="flex justify-center text-primary cursor-pointer hover:font-semibold" v-if="data.doctorcare.source_url">
                        <feather-icon class="mr-1" icon="LinkIcon" svgClasses="h-4 w-4"></feather-icon>
                        <span @click="$common.openExternalUrl(data.doctorcare.source_url)">檢視原始資料</span>
                    </div>
                    <img :src="url" class="w-full h-auto" v-if="['jpeg', 'jpg', 'png'].includes(data.doctorcare.ext.toLowerCase())" />
                    <div style="width: 100%; height: 100vh; box-sizing: border-box" v-if="['pdf'].includes(data.doctorcare.ext.toLowerCase())">
                        <iframe :src="url" style="width: 100%; height: 100%; border: none" frameborder="0"></iframe>
                    </div>
                </div>
                <div class="w-full mb-base" v-if="data.status == 0">
                    <div class="mb-6 flex flex-col gap-3">
                        <p class="text-2xl">文檔判讀</p>
                        <div class="flex flex-wrap gap-4">
                            <vs-radio v-for="item in data.doctorcare.dctype.interps" v-model="interp" vs-name="interp" :vs-value="item.code">{{ $common.getLang(item) }}</vs-radio>
                        </div>
                    </div>
                    <div class="mb-6 w-full max-w-md flex flex-col gap-2">
                        <div class="flex justify-between items-end">
                            <span class="text-2xl">就醫建議</span>
                        </div>
                        <Words v-model="opinion" :type="'memop'"></Words>
                    </div>
                    <div class="mb-6 w-full max-w-md">
                        <vs-checkbox v-model="showPatientResult">在民眾端報告呈現判讀結果與建議</vs-checkbox>
                    </div>
                    <div class="flex space-x-4">
                        <vs-button class="cd-buttom-4w rounded" @click="finish">完成判讀</vs-button>
                        <vs-button class="cd-buttom-2w rounded" type="border" @click="close">取消</vs-button>
                    </div>
                </div>
                <div class="w-full mb-base" v-else>
                    <div class="mb-6 text-xl">
                        <span>判讀結果：{{ getInterpName(interp) ? getInterpName(interp) : '未填寫' }}</span>
                    </div>
                    <div class="mb-6 text-xl">
                        <span>治療建議：{{ data.opinion ? data.opinion : '未填寫' }}</span>
                    </div>
                    <div>
                        <vs-button class="cd-buttom-2w rounded" @click="close">關閉</vs-button>
                    </div>
                </div>
            </div>
            <div class="p-4 lg:p-8 lg:w-1/3 w-full order-1 lg:order-2 min-w-xs flex flex-col gap-8 bg-grey-light rounded">
                <div class="flex flex-col gap-3">
                    <span class="text-2xl">民眾資訊</span>
                    <span>{{ $common.getGender(patientInfo.sex) }}性 {{ $common.getAge(patientInfo.birthday) }}歲</span>
                    <!-- 病史 -->
                    <div class="space-y-1">
                        <div class="flex flex-wrap whitespace-no-wrap" v-for="history in medHistory" :key="history.code" v-if="history.data.length > 0">
                            <div class="whitespace-no-wrap w-28">
                                <span class="cd-text-muted">{{ $common.getLangDB(history) }}</span>
                            </div>
                            <div class="gap-x-2 flex flex-wrap">
                                <span v-for="item in history.data" :key="item.mh_item_id">{{ $common.getLangDB(item) }}</span>
                                <span v-if="history.data.length == 0">無</span>
                            </div>
                        </div>
                    </div>
                    <!-- 生命健康數據 -->
                    <div class="w-full space-y-1">
                        <div v-for="sign in vitalSign" :key="sign.mtype_id" class="flex flex-wrap">
                            <div class="whitespace-no-wrap w-28">
                                <span class="cd-text-muted">{{ $common.getLangDB(sign.name) }}</span>
                            </div>
                            <div class="whitespace-no-wrap w-64 flex justify-between items-center flex-wrap gap-1">
                                <span>{{ sign.latest_value }} {{ sign.unit }}</span>
                                <span class="cd-text-muted text-sm">{{ sign.measuretime }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <more :active="showReportAndInterpHistory" @changeActive="showReportAndInterpHistory = !showReportAndInterpHistory">
                        <template v-slot:content>
                            <!-- 健檢報告 -->
                            <div class="mb-8">
                                <div class="text-2xl">
                                    <span>健檢報告</span>
                                </div>
                                <div class="-mx-3">
                                    <collapse :type="'report_' + index" v-for="(report, index) in reports" :key="index">
                                        <template v-slot:label>
                                            <span>{{ $common.getLangDB(report) }}</span>
                                        </template>
                                        <template v-slot:content>
                                            <div class="px-3">
                                                <div v-for="sub in report.report" :key="sub.sub_id" class="flex flex-wrap">
                                                    <div class="whitespace-no-wrap w-56 flex items-center flex-wrap gap-1">
                                                        <span class="cd-text-muted">{{ $common.getLangDB(sub) }}</span>
                                                        <span v-if="sub.standard_min != null && sub.standard_max != null">({{ sub.standard_min }}~{{ sub.standard_max }}) </span>
                                                    </div>
                                                    <div class="whitespace-no-wrap w-32 flex justify-between items-center flex-wrap gap-1">
                                                        <span>{{ sub.value ? sub.value : '-' }}</span>
                                                        <span class="cd-text-muted text-sm">{{ sub.date }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </collapse>
                                </div>
                            </div>
                            <!-- 心電圖的文檔會診紀錄 -->
                            <div class="mb-8">
                                <div class="text-2xl">
                                    <span>心電圖的文檔會診紀錄</span>
                                </div>
                                <div class="-mx-3">
                                    <collapse :type="'opinion_' + opinion.id" v-for="opinion in opinions" :key="opinion.id" v-if="opinion.dctype_interp || opinion.opinion">
                                        <template v-slot:label>
                                            <span>{{ opinion.updated_at }}</span>
                                        </template>
                                        <template v-slot:content>
                                            <div class="px-4">
                                                <p>判讀結果：{{ getInterpName(opinion.dctype_interp) ? getInterpName(opinion.dctype_interp) : '未填寫' }}</p>
                                                <p>治療建議：{{ opinion.opinion ? opinion.opinion : '未填寫' }}</p>
                                            </div>
                                        </template>
                                    </collapse>
                                </div>
                            </div>
                        </template>
                        <template v-slot:inActiveBtn> 顯示更多 </template>
                        <template v-slot:activeBtn> 隱藏更多 </template>
                    </more>
                </div>
            </div>
        </div>
    </vs-popup>
</template>
<script>
import { getOpinions, updateOpinion } from '@/api/user'
import { mapState, mapGetters } from 'vuex'
import importSetWord from '@/components/importSetWord'
import Words from '@/components/Module/Words'
import collapse from '@/components/Common/collapse'
import more from '@/components/Common/more'
export default {
    components: {
        collapse,
        more,
        importSetWord,
        Words,
    },
    props: {
        interpPopup: {
            type: Boolean,
            default: false,
            required: true,
        },
        opinion_id: {
            type: Number,
            default: 0,
            required: false,
        },
    },
    data() {
        return {
            active: false,
            data: null,
            interp: null,
            opinion: '',
            showPatientResult: true,
            patientInfo: null,
            medHistory: null,
            vitalSign: null,
            reports: null,
            opinions: null,
            showReportAndInterpHistory: false,
        }
    },
    watch: {
        interpPopup(n) {
            this.active = n
            if (n) {
                this.getData()
            }
        },
    },
    computed: {
        ...mapState(['partner_id']),
        ...mapGetters(['wordsByCode']),
        url() {
            const uploadpath = this.data.doctorcare.dctype.uploadpath
            return process.env.VUE_APP_UPLOAD + '/' + uploadpath + this.data.doctorcare.imagefile
        },
        imgWidth() {
            const innerWidth = window.innerWidth
            const width = innerWidth > 630 ? innerWidth - 100 : innerWidth - 100
            return width
        },
        opinionWords() {
            return this.wordsByCode('o')
        },
    },
    methods: {
        // 關閉文檔會診判讀視窗
        close() {
            this.interp = null
            this.opinion = ''
            this.$emit('closeInterp')
        },
        // 取文檔會診
        async getData() {
            const payload = {
                opinion_id: this.opinion_id,
                partner_id: this.partner_id,
                withDoctorcare: 1,
                withPatient: 1,
                withPatientDetail: 1,
            }
            const res = await getOpinions(payload)
            this.data = res.data.data.data[0]
            if (this.data.dctype_interp) this.interp = this.data.dctype_interp
            if (this.data.opinion) this.opinion = this.data.opinion
            this.patientInfo = this.data.patient
            this.medHistory = this.data.medHistory
            this.vitalSign = this.data.vitalSign
            this.reports = this.data.reports
            this.opinions = this.data.opinions
        },
        // 完成判讀
        async finish() {
            if (!this.interp) {
                this.$store.dispatch('notify', { color: 'danger', title: '請勾選判讀' })
                return
            }
            const payload = {
                opinion_id: this.opinion_id,
                dctype_interp: this.interp,
                opinion: this.opinion,
                status: 1,
                showPatientResult: this.showPatientResult,
            }
            const res = await updateOpinion(payload)
            this.$bus.$emit('finishInterp', this.opinion_id)
            this.$store.dispatch('notify', { color: 'grey', title: '判讀成功' })
            this.close()
        },
        // 匯入常用字句
        importMsg(msg) {
            console.log('msg', msg)
            // 判斷最後一個字元是不是換行符號
            let lastWord = this.opinion.split('')[this.opinion.split('').length - 1]
            if (lastWord != '\n' && lastWord != undefined) {
                this.opinion = this.opinion + '\n'
            }
            this.opinion += msg
        },
        getInterpName(interp) {
            const interps = this.data.doctorcare.dctype.interps
            const index = _.findIndex(interps, { code: interp })
            return this.$common.getLang(interps[index])
        },
    },
}
</script>
