<template>
    <div>
        <div class="overflow-hidden transition-all duration-500" :class="{ 'max-h-0': !active, 'max-h-screen': active }">
            <slot name="content"></slot>
        </div>
        <div class="mt-2" v-show="!active">
            <span class="text-primary cursor-pointer hover:font-semibold" @click="changeActive">
                <slot name="inActiveBtn"></slot>
            </span>
        </div>
        <div class="mt-2" v-show="active">
            <span class="text-primary cursor-pointer hover:font-semibold" @click="changeActive">
                <slot name="activeBtn"></slot>
            </span>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        active: {
            type: Boolean,
            default: false,
            require: true,
        },
    },
    methods: {
        changeActive() {
            this.$emit('changeActive')
        },
    },
}
</script>
