<template>
    <div>
        <!-- 房間清單 -->
        <!-- <RoomList :openPopup="activeRoom" @closePopup="closeRoom"></RoomList> -->
        <!-- 通話清單 -->
        <CallList
            :openPopup="activeCall"
            :title="callListPayload.title.toString()"
            :pid="callListPayload.pid.toString()"
            :noContact="callListPayload.noContact"
            :noEnterprise="callListPayload.noEnterprise"
            :noPartner="callListPayload.noPartner"
            :qid="callListPayload.qid.toString()"
            :sn_id="callListPayload.sn_id.toString()"
            :transfer="callListPayload.transfer"
            @closePopup="closeCall"
        ></CallList>
        <!-- 選擇合作夥伴視窗 -->
        <selectPartner :openPopup="activeSelectPartner" @closePartnerPopup="closePartner"></selectPartner>
        <!-- 掛號視窗 -->
        <appt :activePopup="activeAppt" :patientData="patient" :mode="apptMode" @closeApptPopup="closeAppt"></appt>
        <!-- 聊天室視窗 -->
        <chat :chatPopup="activeChat" :smid="parseInt(smid)" :sname="sname" @closeChat="closeChat"></chat>
        <!-- 發送簡訊邀請民眾視窗 -->
        <invite-popup :invitePopupActive="activeInvite" :qid="invite.qid ? parseInt(invite.qid) : 0" :room_id="invite.room_id ? parseInt(invite.room_id) : 0" :pmid="invite.mid" @closePopup="closeInvite"></invite-popup>
        <!-- Error dialog -->
        <errDialog :openPopup="activeErrDialog" :title="errDialogPayload.title" :content="errDialogPayload.content" @closeErrDialogPopup="closeErrDialog"></errDialog>
        <!-- 交辦案件視窗 -->
        <assign :assignPopup="activeAssign" :sn_id="parseInt(assignPayload.sn_id)" @closeAssign="closeAssign"></assign>
        <!-- 新增案件視窗 -->
        <sn :snPopup="activeSn" :pid="parseInt(snPayload.pid)" :pname="snPayload.pname" @closeSn="closeSn"></sn>
        <!-- 推薦商品 -->
        <product :productPopupActive="activeProduct" @closePopup="closeProduct" :sn_id="parseInt(productPayload.sn_id)" :pid="parseInt(productPayload.pid)" :pname="productPayload.pname"></product>
        <!-- 發送問卷 -->
        <question :questionPopup="activeQuestion" @closeQuestion="closeQuestion" :pid="parseInt(questionPayload.pid)" :name="questionPayload.name"></question>
        <!-- 文檔會診 -->
        <interp :interpPopup="activeInterp" @closeInterp="closeInterp" :opinion_id="parseInt(interpPayload.opinion_id)"></interp>
        <!-- 新增會員 -->
        <member :memberPopup="activeMember" @closeMember="closeMember" :type="memberPayload.type" :default_eid="parseInt(memberPayload.default_eid)" :event_id="parseInt(memberPayload.event_id)" :hpa_event_id="parseInt(memberPayload.hpa_event_id)"></member>
        <!-- AI建議 -->
        <aiSuggestion :aiSuggestionPopup="activeAiSuggestion" @closeAiSuggestion="closeAiSuggestion" v-model="aiSuggestionPayload.suggestion" :desc="aiSuggestionPayload.desc" :showApply="aiSuggestionPayload.showApply" :showCopy="aiSuggestionPayload.showCopy"></aiSuggestion>
    </div>
</template>
<script>
import errDialog from '@/components/errDialog.vue'
import selectPartner from '@/components/selectPartner.vue'
import appt from '@/components/apptBtn.vue'
import chat from '@/components/sendChat'
import InvitePopup from '@/components/invitePopup'
import CallList from '@/components/Call/CallList'
import Assign from '@/components/CRM/AssignForm'
import Sn from '@/components/CRM/SNForm'
import product from '@/components/productPopup.vue'
import Question from '@/components/Question/SendQuestionForm.vue'
import Interp from '@/components/Opinion/Interp.vue'
import Member from '@/components/MemberMgmt/MemberForm.vue'
import aiSuggestion from '@/components/Module/AiSuggestion.vue'
// import RoomList from '@/components/Room/RoomList'
export default {
    components: { errDialog, selectPartner, appt, chat, InvitePopup, CallList, Assign, Sn, product, Question, Interp, Member, aiSuggestion },
    data() {
        return {
            // activeRoom: false,
            activeCall: false,
            activeSelectPartner: false,
            activeErrDialog: false,
            activeAppt: false,
            activeChat: false,
            activeInvite: false,
            activeAssign: false,
            activeSn: false,
            activeProduct: false,
            activeQuestion: false,
            activeInterp: false,
            activeMember: false,
            activeAiSuggestion: false,
            apptMode: 0,
            patient: {
                pid: null,
                name: null,
            },
            smid: 0,
            sname: '',
            invite: {
                qid: 0,
                room_id: 0,
                mid: 0,
            },
            callListPayload: {
                title: this.$t('components.call.call'),
                pid: 0,
                noContact: false,
                noEnterprise: false,
                noPartner: false,
                qid: 0,
                sn_id: 0,
                transfer: false,
            },
            errDialogPayload: {
                title: '',
                content: '',
            },
            assignPayload: {
                sn_id: 0,
            },
            snPayload: {
                pid: 0,
                pname: '',
            },
            productPayload: {
                sn_id: 0,
                pid: 0,
                pname: '',
            },
            questionPayload: {
                pid: 0,
            },
            interpPayload: {
                opinion_id: 0,
            },
            memberPayload: {
                type: '',
                default_eid: 0,
                event_id: 0,
                hpa_event_id: 0,
            },
            aiSuggestionPayload: {
                desc: '',
                suggestion: '',
                showApply: true,
                showCopy: true,
            },
        }
    },
    created() {
        this.createBus()
    },
    methods: {
        createBus() {
            this.$bus.$on('selectPartner', () => {
                this.openPartner()
            })
            this.$bus.$on('appt', (item) => {
                this.apptMode = item.apptMode
                this.patient = item.patient
                this.openAppt()
            })
            this.$bus.$on('chat', (item) => {
                this.smid = item.smid
                this.sname = item.sname
                this.openChat()
            })
            this.$bus.$on('invite', (item) => {
                this.invite = item
                this.openInvite()
            })
            this.$bus.$on('callList', (item) => {
                if (item.title !== undefined) this.callListPayload.title = item.title
                if (item.pid !== undefined) this.callListPayload.pid = item.pid
                if (item.noContact !== undefined) this.callListPayload.noContact = item.noContact
                if (item.noEnterprise !== undefined) this.callListPayload.noEnterprise = item.noEnterprise
                if (item.noPartner !== undefined) this.callListPayload.noPartner = item.noPartner
                if (item.qid !== undefined) this.callListPayload.qid = item.qid
                if (item.sn_id !== undefined) this.callListPayload.sn_id = item.sn_id
                if (item.transfer !== undefined) this.callListPayload.transfer = item.transfer
                this.openCall()
            })
            this.$bus.$on('errDialog', (item) => {
                this.errDialogPayload.content = item.content
                this.errDialogPayload.title = item.title
                this.openErrDialog()
            })
            this.$bus.$on('assign', (item) => {
                this.assignPayload.sn_id = item.sn_id
                this.openAssign()
            })
            this.$bus.$on('sn', (item) => {
                this.snPayload.pid = this.$common.isset(item.pid) ? item.pid : 0
                this.snPayload.pname = this.$common.isset(item.pname) ? item.pname : ''
                this.openSn()
            })
            this.$bus.$on('product', (item) => {
                this.productPayload.sn_id = this.$common.isset(item.sn_id) ? item.sn_id : 0
                this.productPayload.pid = this.$common.isset(item.pid) ? item.pid : 0
                this.productPayload.pname = this.$common.isset(item.pname) ? item.pname : ''
                this.openProduct()
            })
            this.$bus.$on('question', (item) => {
                this.questionPayload.pid = this.$common.isset(item.pid) ? item.pid : 0
                this.questionPayload.name = this.$common.isset(item.name) ? item.name : ''
                this.openQuestion()
            })
            this.$bus.$on('interp', (item) => {
                this.interpPayload.opinion_id = this.$common.isset(item.opinion_id) ? item.opinion_id : 0
                this.openInterp()
            })
            this.$bus.$on('member', (item) => {
                this.memberPayload.type = this.$common.isset(item.type) ? item.type : 'member'
                this.memberPayload.default_eid = this.$common.isset(item.default_eid) ? item.default_eid : 0
                this.memberPayload.event_id = this.$common.isset(item.event_id) ? item.event_id : 0
                this.memberPayload.hpa_event_id = this.$common.isset(item.hpa_event_id) ? item.hpa_event_id : 0
                this.openMember()
            })
            this.$bus.$on('aiSuggestion', (item) => {
                this.aiSuggestionPayload.desc = this.$common.isset(item.desc) ? item.desc : ''
                this.aiSuggestionPayload.suggestion = this.$common.isset(item.suggestion) ? item.suggestion : ''
                this.aiSuggestionPayload.showApply = this.$common.isset(item.showApply) ? item.showApply : true
                this.aiSuggestionPayload.showCopy = this.$common.isset(item.showCopy) ? item.showCopy : true
                this.openAiSuggestion()
            })
        },
        // openRoom() {
        //     this.activeRoom = true
        // },
        // closeRoom() {
        //     this.activeRoom = false
        // },
        openCall() {
            this.activeCall = true
        },
        closeCall() {
            this.callListPayload = {
                title: this.$t('components.call.call'),
                pid: 0,
                noContact: false,
                noEnterprise: false,
                noPartner: false,
                qid: 0,
                sn_id: 0,
                transfer: false,
            }
            this.activeCall = false
        },
        openErrDialog() {
            this.activeErrDialog = true
        },
        closeErrDialog() {
            this.activeErrDialog = false
        },
        openPartner() {
            this.activeSelectPartner = true
        },
        closePartner() {
            this.activeSelectPartner = false
        },
        openAppt() {
            this.activeAppt = true
        },
        closeAppt() {
            this.activeAppt = false
        },
        openChat() {
            this.activeChat = true
        },
        closeChat() {
            this.activeChat = false
        },
        openInvite() {
            this.activeInvite = true
        },
        closeInvite() {
            this.activeInvite = false
        },
        openAssign() {
            this.activeAssign = true
        },
        closeAssign() {
            this.activeAssign = false
        },
        openSn() {
            this.activeSn = true
        },
        closeSn() {
            this.activeSn = false
        },
        openProduct() {
            this.activeProduct = true
        },
        closeProduct() {
            this.activeProduct = false
        },
        openQuestion() {
            this.activeQuestion = true
        },
        closeQuestion() {
            this.activeQuestion = false
        },
        openInterp() {
            this.activeInterp = true
        },
        closeInterp() {
            this.activeInterp = false
        },
        openMember() {
            this.activeMember = true
        },
        closeMember() {
            this.activeMember = false
        },
        openAiSuggestion() {
            this.activeAiSuggestion = true
        },
        closeAiSuggestion() {
            this.activeAiSuggestion = false
        },
    },
    beforeDestroy() {
        this.$bus.$off('selectPartner')
        this.$bus.$off('appt')
        this.$bus.$off('chat')
        this.$bus.$off('invite')
        this.$bus.$off('callList')
        this.$bus.$off('errDialog')
        this.$bus.$off('assign')
        this.$bus.$off('sn')
        this.$bus.$off('product')
        this.$bus.$off('question')
        this.$bus.$off('interp')
        this.$bus.$off('member')
        this.$bus.$off('aiSuggestion')
    },
}
</script>
