<template>
    <vs-popup :active.sync="active" title="發送問卷" style="z-index: 51001" @close="close">
        <div class="p-4 space-y-8">
            <!-- 民眾 -->
            <div>
                <span>發送對象：{{ name }}</span>
            </div>
            <!-- 選擇單位 -->
            <div>
                <span>* 選擇單位</span>
                <unit-tree-select :units="treeUnits" v-model="eid" />
            </div>
            <!-- 選擇問卷 -->
            <div>
                <span>* 選擇問卷</span>
                <el-select class="w-full" v-model="code" clearable popper-class="custom-dropdown" popper-append-to-body>
                    <el-option v-for="item in questions" :key="item.code" :label="item.title" :value="item.code">
                        {{ item.title }}
                    </el-option>
                </el-select>
            </div>
            <!-- 選擇管道（哪個Line官方帳號） -->
            <div>
                <span>* 選擇管道</span>
                <el-select class="w-full" v-model="line_official_id" clearable popper-class="custom-dropdown" popper-append-to-body>
                    <el-option v-for="item in line_list" :key="item.line_official_id" :label="item.name" :value="item.line_official_id">
                        {{ item.name }}
                    </el-option>
                </el-select>
            </div>
            <!-- 發送 -->
            <div class="flex justify-end gap-4">
                <vs-button class="cd-button-2w rounded whitespace-no-wrap" type="flat" @click="close">{{ $t('popup.cancel') }}</vs-button>
                <vs-button class="cd-button-4w rounded whitespace-no-wrap" @click="sendQuestion">發送問卷</vs-button>
            </div>
        </div>
    </vs-popup>
</template>
<script>
import { getQuestions, sendQuestion } from '@/api/user'
import UnitTreeSelect from '@/components/Module/UnitTreeSelect.vue'
import { mapState } from 'vuex'
export default {
    components: {
        UnitTreeSelect,
    },
    props: {
        questionPopup: {
            type: Boolean,
            default: false,
            required: true,
        },
        pid: {
            type: Number,
            default: 0,
            required: true,
        },
        name: {
            type: String,
            default: '',
            required: true,
        },
    },
    data() {
        return {
            active: false,
            questions: [],
            eid: null,
            code: null,
            line_official_id: null,
        }
    },
    watch: {
        questionPopup(n) {
            this.active = n
            if (n) {
                // 預設值
                const savedUnit = localStorage.getItem('selectedUnit')
                this.eid = savedUnit ? JSON.parse(savedUnit) : null
                this.getQuestions()
            }
        },
    },
    computed: {
        ...mapState(['partner_id', 'line_list']),
        treeUnits() {
            return this.$common.buildUnitTree()
        },
    },
    methods: {
        // 關閉交辦案件視窗
        close() {
            this.$emit('closeQuestion')
        },
        // 取問卷
        async getQuestions() {
            try {
                const payload = {
                    partner_id: this.partner_id,
                    status: 1,
                }
                const res = await getQuestions(payload)
                this.questions = res.data.data.data
            } catch (error) {
                console.error('取問卷失敗', error)
            }
        },
        // 發送問卷連結
        async sendQuestion() {
            try {
                if (!this.eid) {
                    this.$store.dispatch('notify', { color: 'grey', title: '請選擇單位' })
                    return
                }
                if (!this.code) {
                    this.$store.dispatch('notify', { color: 'grey', title: '請選擇問卷' })
                    return
                }
                if (!this.line_official_id) {
                    this.$store.dispatch('notify', { color: 'grey', title: '請選擇管道' })
                    return
                }
                const payload = {
                    partner_id: this.partner_id,
                    eid: this.eid,
                    code: this.code,
                    line_official_id: this.line_official_id,
                    pid: this.pid,
                }
                await sendQuestion(payload)
                this.$store.dispatch('notify', { color: 'grey', title: '發送成功' })
                this.close()
            } catch (error) {
                console.error('發送問卷連結失敗', error)
            }
        },
    },
}
</script>
