<template>
    <!-- AI建議 -->
    <vs-popup :active.sync="active" title="AI建議" style="z-index: 51002" @close="close">
        <div class="flex flex-col gap-4">
            <div>
                <p>{{ desc }}</p>
            </div>
            <div>
                <textarea class="w-full cd-emr-textarea" :rows="10" v-model="suggestion"></textarea>
            </div>
            <div class="flex justify-end gap-4">
                <vs-button color="dark" type="border" class="rounded whitespace-no-wrap" @click="close"> 關閉 </vs-button>
                <vs-button color="dark" type="border" class="rounded whitespace-no-wrap" @click="copy" v-if="showCopy">複製</vs-button>
                <vs-button class="rounded whitespace-no-wrap" color="success" @click="apply" v-if="showApply">套用</vs-button>
            </div>
        </div>
    </vs-popup>
</template>
<script>
export default {
    props: {
        aiSuggestionPopup: {
            type: Boolean,
            default: false,
            required: true,
        },
        desc: {
            type: String,
            default: '',
            required: false,
        },
        value: {
            type: String,
            default: '',
            required: false,
        },
        showApply: {
            type: Boolean,
            default: true,
            required: false,
        },
        showCopy: {
            type: Boolean,
            default: true,
            required: false,
        },
    },
    data() {
        return {
            active: false,
        }
    },
    watch: {
        aiSuggestionPopup(v) {
            this.active = v
        },
    },
    computed: {
        suggestion: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
    },
    methods: {
        // 關閉彈窗
        close() {
            this.$emit('closeAiSuggestion')
        },
        // 套用建議
        apply() {
            this.$bus.$emit('applySuggestion')
            this.close()
        },
        // 複製到剪貼簿
        copy() {
            this.$common.copyTextToClipboard(this.suggestion)
        },
    },
}
</script>
